import React from "react";
import About from '../components/About'

function Home() {

  return (
    <div className="col-10 p-4 d-flex">
      <div className="row h-100 align-items-center">

      <About/>
      </div>
    </div>
  );
}

export default Home;
